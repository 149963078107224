import React from "react";
import Page from "../../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Hundflytvast({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Jaramba recension (2022) - En pedagogisk app för barn"

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Hur mycket kostar barnappen Jaramba?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Jaramba kostar 99 kronor per månad, men som ny användare får testa på barnappen helt gratis."
        }
      },
      {
        "@type": "Question",
        "name": "Hur många barn kan dela på ett konto?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Det går att ha upp till tre barn per prenumeration. Varje barn kan skapa sin egen profil för att senare börja lära sig i appen."
        }
      }
    ]
  }

  return (
    <>
      <Page title={title} description="Jaramba är en pedagogisk app för barn i förskoleåldern med syftet att hjälpa barnet att bygga upp sitt ordförråd samtidigt som barnet också har roligt." schema={schema}>
      <div className="w-full py-3 bg-gray-100"><p className="flex items-center max-w-screen-xl mx-auto px-4 justify-between w-full">Inlägget innehåller reklam genom annonslänkar.</p></div>
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="jaramba" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>Jaramba är en pedagogisk app för barn i förskoleåldern med syftet att hjälpa barnet att bygga upp sitt ordförråd samtidigt som barnet har roligt. Appen innehåller en mängd olika spel, såsom ordbingo och memory, som hjälper barnen att lära sig nya ord.</p>
            <p>I denna artikeln kommer vi gå igenom appen Jaramba för att kunna ta reda på om det är en app som är värd att prova för dig och ditt barn.</p>
            <h2 id="-r-jaramba-en-bra-app-f-r-barn-">Är Jaramba en bra app för barn?</h2>
            <p>Jaramba är en bra app för barn. Appen innehåller många olika engagerande och underhållande spel som hjälper barnen att lära sig nya ord, samtidigt som barnet har roligt.</p>
            <p>För de mindre barnen så är appen ett ypperligt verktyg för att lära sig nya ord och bygga upp sitt ordförråd. Barnen kommer att ha stor glädje av de olika spelen som finns i appen, samtidigt som det gör skärmtiden mer meningsfull.</p>
            <p>Det går också att anpassa appen efter barnets egna behov, och appen är byggd för att barnen ska kunna styra takten på sitt lärande, följa sitt eget intresse och samtidigt ha roligt på vägen.</p>
            <p>För att skapa ett fungerande gränssnitt så appen även testats tillsammans med barngrupper i förskolan för att den ska bli så bra som möjligt.</p>
            <div className="w-full flex justify-center">
              <a href="https://track.adtraction.com/t/t?a=1724259980&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Jaramba gratis här!</button>
              </a>
            </div>
            <h2 id="f-r-vilka-barn-passar-jaramba-">För vilka barn passar Jaramba?</h2>
            <p>Appen riktar sig till de allra minsta barnen i åldern 2-5 år, men hur bra appen är för ditt barn kommer också bero på hur långt barnet är i sin egna utveckling.</p>
            <p>Det finns en stor chans att ett äldre barn på 4-5 år redan har ett relativt bra ordförråd och andra kunskaper och då kommer appen inte vara lika intressant eller rolig för barnet.</p>
            <p>Mycket i appen handlar om att hjälpa barnet att utveckla sitt språk och ordförråd, så om ditt barn redan har ett ganska brett ordförråd kommer appen inte att vara lika nyttigt.</p>
            <p>Appen innehåller dock flera minispel och andra aktiviteter som kan vara användbara. Dessa minispel kan exemeplvis lära barnet grundläggande matematik, hur man värnar om djur och natur, finmotorik och hur man är en bra kompis för att nämna några exempel.</p>
            <h2 id="hur-fungerar-barnappen-jaramba-">Hur fungerar barnappen Jaramba?</h2>
            <p>För att börja använda appen Jaramba behöver du först skapa ett konto på deras hemsida och ladda ner appen från App Store eller Google Play.</p>
            <p>När du har laddat ner och installerat appen kan du skapa en profil för ditt barn. Skriv i namn och födelseår och välj en avatar för barnet.</p>
            <GatsbyImage image={data.konto.childImageSharp.gatsbyImageData} className="mb-5 rounded-xl" alt="jaramba skapa konto" />
            <p>När du gjort det kan du börja använda appen, och på huvudskärmen i appen kommer du se en översikt över alla olika spel som finns tillgängliga.</p>
            <p>Totalt sätt kan du ha upp till 3 barn kopplat till ett och samma konto. Om du har flera barn kommer du därför kunna använda appen med dem alla.</p>
            <p>Spelen och aktiviteteter som går att göra i appen skiljer sig en hel del åt. Det finns spel som handlar om att lära sig nya ord, olika filmer och klassiskt memory för barnen att prova på.</p>
            <GatsbyImage image={data.meny.childImageSharp.gatsbyImageData} className="mb-5 rounded-xl" alt="jaramba meny" />
            <h2 id="vad-g-r-det-att-g-ra-i-appen-jaramba-">Vad går det att göra i appen Jaramba?</h2>
            <p>Det finns över 800 olika aktiviteter som går att göra i appen. Dessa olika aktiviteter och minispel  kommer att hjälpa barnet att lära sig om olika saker.</p>
            <p>De olika aktiivteterna delas också in i olika kategorier för att det ska bli enklare för barnet att hitta rätt. Några exempel på de olika kategorierna är:</p>
            <ul>
              <li>Brokiga (kända från Stina Wirséns populära barnböcker)</li>
              <li>Miffy &amp; Vänner</li>
              <li>Timmy Lamm</li>
              <li>Pocoyo</li>
              <li>Hello Kitty</li>
            </ul>
            <p>Om du exempelvis väljer en kategori såsom Hello Kitty finns det i sin tur flera olika spel såsom Hello Kitty börjar i skolan eller ute på picknick.</p>
            <p>Det finns många olika typer av aktiviteter och spel, och det kommer alltså att finnas något för alla barn.</p>
            <GatsbyImage image={data.ex2.childImageSharp.gatsbyImageData} className="mb-5 rounded-xl" alt="jaramba spel" />
            <h2 id="-r-jaramba-en-gratis-app-">Är Jaramba en gratis app?</h2>
            <p>Det är gratis att prova på Jaramba i en månad, men efter provperioden måste du betala en månatlig prenumerationsavgift på 99 kronor per månad för att få fortsätta använda appen.</p>
            <p>En månad ger dig gott om tid för att testa appen och se om det är något för ditt barn, och det finns ingen bindningstid på prenumerationen.</p>
            <p>För att påbörja provperioden behöver du skapa ett konto på deras hemsida och ange dina betalningsuppgifter. Du kommer inte att debiteras förrän provperioden är slut.</p>
            <div className="w-full flex justify-center">
              <a href="https://track.adtraction.com/t/t?a=1724259980&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Jaramba gratis här!</button>
              </a>
            </div>
            <h2 id="vilka-ligger-bakom-barnappen-jaramba-">Vilka ligger bakom barnappen Jaramba?</h2>
            <p>Företaget som ligger bakom barnappen Jaramba heter AMPD AB och är ett engagerat gäng som själva skriver att de &quot;brinner för att skapa Sveriges bästa barnapp&quot;.</p>
            <p>På deras hemsida skriver de också att deras &quot;mål med Jaramba är att kunna ge alla barn den bästa och roligaste grunden för ett livslångt lärande&quot;.</p>
            <p>Syftet med Jaramba är först och främst att bygga upp barns ordförråd men appen kan även användas för att så mer än så – grundläggande matematik, hur man värnar om djur &amp; natur, finmotorik och hur man är en bra kompis för att bara använda några exempel.</p>
            <h2 id="var-finns-appen-tillg-nglig-">Var finns appen tillgänglig?</h2>
            <p>Det går att ladda ner appen både App Store och Google Play Store. Det finns ingen möjlighet att spela på datorn just nu men du som föräldrar kan hantera ditt konto genom att logga in på deras webbplats.</p>
            <p>För att börja använda appen så skapar du enklast ett konto på deras hemsida och laddar ner appen sedan från App Store eller Google Play.</p>
            <h2 id="vad-r-skillnaden-p-jaramba-och-andra-skolappar-">Vad är skillnaden på Jaramba och andra skolappar?</h2>
            <p>Det finns flera andra skolappar på marknaden, och det kan därför vara svårt att veta vad som skiljer dem åt.</p>
            <p>Den största skillnaden mellan Jarmaba, jämfört med andra skolappar, såsom <a href="/recensioner/zcooly/">Zcooly</a> och <a href="/recensioner/hejalbert/">Albert</a> är att Jaramba riktar sig till de allra minsta barnen.</p>
            <p>Jaramba är också en app som fokuserar mer på att barnet ska utveckla sitt ordförråd och det är en mångsidig app som kan användas för flera olika saker.</p>
            <p>Zcooly och Albert, å andra sidan, riktar sig främst till de lite äldre barnen och huvudfokuset ligger mer på matematiken.</p>
            <p>Det är alltså två helt olika typer av app och det är upp till dig som förälder att avgöra vilken app som passar bäst.</p>
            <p>Om du har ett barn som går i förskola eller något yngre barn så är Jaramba den appen som vi skulle rekommendera.</p>
            <p>Jaramba är en pedagogisk app för barn i förskoleåldern med syftet att hjälpa barnet att bygga upp sitt ordförråd samtidigt som barnet också har roligt.</p>
            <h2 id="vanliga-fr-gor-om-barnappen-jaramba">Vanliga frågor om barnappen Jaramba</h2>
            <p>Nedan går vi igenom några av de vanligaste frågorna om appen Jaramba.</p>
            <h3 id="vad-r-jaramba-f-rskola-">Vad är Jaramba förskola?</h3>
            <p>Jaramba har också en tjänst och app som endast riktar sig förskolor som kallas för Jaramba förskola. Detta är en app som endast används på förskolor.</p>
            <p>Jaramba förskola är alltså inte en app för föräldrar och barnen hemma, utan det är en app för förskolor och deras pedagoger.</p>
            <p>Det är också en app som är populär bland många pedagoger. De tycker exempelvis att enkelheten och att Jaramba täcker hela läroplanen är en stor fördel. Det är också lätt att hitta något för varje barn på förskolan.</p>
            <h3 id="vad-tycker-andra-om-jaramba-">Vad tycker andra om Jaramba?</h3>
            <p>Jaramba har fått ett mycket bra betyg bland både föräldrar och pedagoger och det finns många positiva omdömen om appen.</p>
            <p>Föräldrar gillar främst att appen är pedagogisk och rolig för barnen, samtidigt som det gör skärmtiden mer meningsfull. Appen ger föräldrarna lite egentid samtidigt som barnet lär sig något nytt.</p>
            <p>Pedagogerna upplever appen som en stor hjälp i det pedagogiska arbetet, och de gillar bland annat att Jaramba täcker hela läroplanen för förskolan, och det är en app som även de minsta barnen kan använda.</p>
            <p>En nackdel med appen är däremot det visuella biten och själva designen, men detta är en mindre nackdel eftersom det inte verkar vara något som påverkar barnens lärande eller upplevelse av appen.</p>
            <p>Appen Jaramba är alltså en mycket populär och uppskattad app, både bland föräldrar, barn och pedagoger.</p>
            <div className="w-full flex justify-center">
              <a href="https://track.adtraction.com/t/t?a=1724259980&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Jaramba gratis här!</button>
              </a>
            </div>
            <br/>
            <br/>
          </article>
        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "jaramba.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    konto: file(relativePath: { eq: "jaramba-konto.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    meny: file(relativePath: { eq: "jaramba-meny.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ex1: file(relativePath: { eq: "jaramba-exercise1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ex2: file(relativePath: { eq: "jaramba-exercise2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
   
  }
`;
